import React, { useState } from 'react';
import useUser from 'contexts/userContext';
import SignUpModal from 'components/SignUpModal';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import BasicButton from 'components/BasicButton';
import colors from 'colors';
import { LogoutIcon } from 'Icons';
import ReactGA from 'react-ga4';

const LoginOrLogoutButton = ({ isInLandingPage }: { isInLandingPage: boolean }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userId, genLogout } = useUser();

  const handleLogin = useUpdatingCallback(() => setIsModalOpen(true));
  const handleLogout = useUpdatingCallback(() => {
    ReactGA.event({
      category: 'User',
      action: 'LoggedOut',
    });
    genLogout();
  });

  if (userId) {
    return isInLandingPage ? (
      <__SimpleButton onClick={handleLogout} text='Logout' />
    ) : (
      <BasicButton
        key='account'
        Icon={LogoutIcon}
        onClick={handleLogout}
        extraCSS={{
          borderRadius: 6,
          marginLeft: 12,
          height: 44,
          backgroundColor: 'rgba(0,0,0,0.2)',
          backdropFilter: 'blur(10px)',
          color: 'rgba(0,0,0,0.5)',
          '&:hover': { color: 'rgba(0,0,0,0.8)' },
          paddingLeft: 8,
        }}
      />
    );
  }

  return (
    <>
      {isInLandingPage ? (
        <__SimpleButton onClick={handleLogin} text='Login' />
      ) : (
        <BasicButton
          key='login'
          text='Login'
          onClick={handleLogin}
          extraCSS={{
            borderRadius: 6,
            marginLeft: 12,
            height: 44,
            backgroundColor: 'rgba(0,0,0,0.2)',
            backdropFilter: 'blur(10px)',
            color: 'rgba(0,0,0,0.5)',
            '&:hover': { color: 'rgba(0,0,0,0.8)' },
          }}
        />
      )}
      <SignUpModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </>
  );
};

const __SimpleButton = ({ onClick, text }: { onClick: (event: any) => void; text: string }) => {
  return (
    <div
      onClick={onClick}
      css={{
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifContent: 'center',
        cursor: 'pointer',
        textDecoration: 'none',
        color: colors.s_text,
        '&:hover, &:focus': {
          textDecoration: 'underline',
        },
        marginRight: 12,
      }}
    >
      {text}
    </div>
  );
};

export default LoginOrLogoutButton;
