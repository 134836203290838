import { useEffect, useRef, useState } from 'react';

function usePollingEffect(
  asyncCallback: Function,
  dependencies: any[],
  {
    interval = 5_000, // 5 seconds,
    onCleanUp = () => {},
    maxPolls = 10,
  }: {
    interval?: number;
    onCleanUp?: () => void;
    maxPolls?: number;
  },
) {
  const [isAlive, setIsAlive] = useState(false);

  const timeoutIdRef = useRef<any | null>(null);
  useEffect(() => {
    if (!isAlive) {
      return;
    }
    let _stopped = false;
    let counter = 0;
    // Side note: preceding semicolon needed for IIFEs.
    (async function pollingCallback() {
      try {
        if (maxPolls !== undefined && maxPolls === counter) {
          _stopped = true;
        }
        counter++;
        await asyncCallback();
      } finally {
        // Set timeout after it finished, unless stopped
        timeoutIdRef.current = !_stopped && setTimeout(pollingCallback, interval);
      }
    })();
    // Clean up if dependencies change
    return () => {
      _stopped = true; // prevent race conditions
      clearTimeout(timeoutIdRef.current);
      onCleanUp();
    };
  }, [...dependencies, interval, isAlive, maxPolls]);

  return {
    startPoll: () => {
      setIsAlive(true);
    },
    killPoll: () => {
      setIsAlive(false);
    },
  };
}

export default usePollingEffect;
