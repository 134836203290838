import React from 'react';
import Transcript from 'components/Transcript';
import Player from 'types/Player';
import VideoPlayer from 'components/VideoPlayer';
import Dimensions from 'utils/Dimensions';

const VideoAndTranscript = ({
  playerRef,
  isPlaying,
  setIsPlaying,
}: {
  playerRef: Player;
  isPlaying: boolean;
  setIsPlaying: Function;
}) => {
  return (
    <div
      css={{
        maxWidth: Dimensions.MAX_VIDEO_WIDTH,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: '100%',
        marginTop: 24,
        marginLeft: 24,
        minWidth: Dimensions.MIN_VIDEO_WIDTH,
      }}
    >
      <div
        css={{
          overflow: 'hidden',
          borderRadius: 12,
          minHeight: '50%',
        }}
      >
        <VideoPlayer playerRef={playerRef} isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
      </div>
      <Transcript playerRef={playerRef} setIsPlaying={setIsPlaying} />
    </div>
  );
};

export default VideoAndTranscript;
