import React from 'react';
import ChatQuiz from './ChatQuiz';
import TextWithClickableTimestamp from 'components/TextWithClickableTimestamp';
import { UserSvg, GPTSvg } from 'SVGs';
import Player from 'types/Player';
import { MessageUser, MessageType, QuizType } from 'types/Message';
import FeedbackButtons from 'components/FeedbackButtons';
import { isMobile } from 'react-device-detect';

const ChatFeedMessage = ({
  type,
  quizType,
  author,
  messageText,
  playerRef,
  setIsPlaying,
  videoId,
  previousMessageText,
  messageID,
}: {
  type?: MessageType;
  quizType?: QuizType;
  author: MessageUser;
  messageText: string;
  playerRef: Player;
  setIsPlaying: Function;
  videoId: string;
  previousMessageText?: string;
  messageID: string;
}) => {
  if (type === 'quiz') {
    return (
      <ChatQuiz
        key={messageID}
        videoId={videoId}
        text={messageText}
        playerRef={playerRef}
        setIsPlaying={setIsPlaying}
        type={quizType}
      />
    );
  }
  return (
    <div
      key={messageID}
      css={{
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: isMobile ? 14 : 24,
        paddingRight: isMobile ? 14 : 24,
        marginTop: 10,
        fontSize: isMobile ? 14 : 16,
        marginBottom: 6,
        width: '100%',
      }}
    >
      <div
        css={{
          display: 'flex',
          flexFlow: 'row nowrap',
        }}
      >
        <div css={{ marginRight: 12 }}>{author === 'USER' ? UserSvg : GPTSvg}</div>
        <TextWithClickableTimestamp
          text={messageText}
          playerRef={playerRef}
          setIsPlaying={setIsPlaying}
        />
      </div>
      {author === 'CHATGPT' && type && (
        <FeedbackButtons
          videoId={videoId}
          messageType={type}
          modelOutput={messageText}
          userInput={previousMessageText}
        />
      )}
    </div>
  );
};

export default React.memo(ChatFeedMessage);
