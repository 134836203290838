import { useTranscript } from 'contexts/transcriptContext';
import { isMobile } from 'react-device-detect';
import { TranscriptSegment, TranscriptWord } from 'types/Transcript';
import Player from 'types/Player';
import useUpdatingCallback from 'utils/useUpdatingCallback';

const Transcript = ({ playerRef, setIsPlaying }: { playerRef: Player; setIsPlaying: Function }) => {
  const { transcript, isLoadingTranscript } = useTranscript();
  const isLoading = isLoadingTranscript || !transcript;

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'scroll',
        paddingLeft: 24,
        paddingRight: 24,
        marginTop: isMobile ? 75 : 10,
        paddingTop: isMobile ? 0 : 14,
        width: '100%',
      }}
    >
      {isLoading ? (
        <>
          <style>{`@keyframes placeHolderShimmer{
          0% {
              background-position: -468px 0;
          }
          100% {
              background-position: 468px 0;
          }
        }`}</style>
          {[...Array(isMobile ? 10 : 25).keys()].map((key) => (
            <__ShimmerElement
              key={`shimmer-${key}`}
              height={15}
              width={__getRandomInt(isMobile ? 100 : 200, isMobile ? 300 : 500)}
            />
          ))}
        </>
      ) : (
        transcript?.segments?.map((segment) => (
          <__SegmentRow
            key={segment.id}
            segment={segment}
            playerRef={playerRef}
            setIsPlaying={setIsPlaying}
          />
        ))
      )}
    </div>
  );
};

const __SegmentRow = ({
  segment,
  playerRef,
  setIsPlaying,
}: {
  segment: TranscriptSegment;
  playerRef: Player;
  setIsPlaying: Function;
}) => {
  const { whole_word_timestamps = [], start, end } = segment;
  return (
    <div
      data-start-time={`${start.toFixed(0)}.0`}
      data-end-time={`${end.toFixed(0)}.0`}
      data-type='transcript-segment'
      css={{
        color: 'rgb(0, 0, 0)',
        fontSize: '16px',
        lineHeight: '30px',
        fontKerning: 'none',
        fontWeight: '400',
        marginBottom: 12,
      }}
    >
      {whole_word_timestamps?.map((word_seg, i) => (
        <__Word
          key={`${segment.id}-${word_seg.word}-${word_seg.timestamp}-${i}}`}
          word_seg={word_seg}
          playerRef={playerRef}
          setIsPlaying={setIsPlaying}
        />
      ))}
    </div>
  );
};

const __Word = ({
  word_seg,
  playerRef,
  setIsPlaying,
}: {
  word_seg: TranscriptWord;
  playerRef: Player;
  setIsPlaying: Function;
}) => {
  const onClick = useUpdatingCallback(() => {
    const player = playerRef?.current;
    player?.seekTo(word_seg.timestamp - 1);
    setIsPlaying(true);
  });
  return (
    <span
      id={`${Math.floor(word_seg.timestamp)}.`}
      data-type='transcript-word'
      onClick={onClick}
      css={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'lightgrey !important',
        },
      }}
    >
      {word_seg.word}&nbsp;
    </span>
  );
};

const __ShimmerElement = ({ width = 100, height = 32 }: { width?: number; height?: number }) => {
  return (
    <div
      css={{
        '-webkit-animation-duration': '2s',
        '-webkit-animation-fill-mode': 'forwards',
        '-webkit-animation-iteration-count': 'infinite',
        '-webkit-animation-name': 'placeHolderShimmer',
        '-webkit-animation-timing-function': 'linear',
        backgroundImage:
          'linear-gradient(to right, #E0E4E5 0%, #f6f7f9 20%, #E0E4E5 40%, #E0E4E5 100%)',
        backgroundRepeat: 'repeat-x',
        backgroundSize: '900px 104px',
        width,
        height,
        minWidth: width,
        minHeight: height,
        backgroundColor: '#E0E4E5',
        borderRadius: 100,
        marginBottom: 12,
      }}
    />
  );
};

function __getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

export default Transcript;
