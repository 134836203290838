import React from 'react';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { useTranscript } from 'contexts/transcriptContext';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import BasicButton from 'components/BasicButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import colors from 'colors';

const ChatFeedForm = ({
  onPostMessage,
  leftActionButtons,
  extraTextAreaCSS = {},
  isWaitingForResponse = false,
}: {
  onPostMessage: Function;
  leftActionButtons: any[];
  extraTextAreaCSS?: any;
  isWaitingForResponse?: boolean;
}) => {
  const { transcript, isLoadingTranscript } = useTranscript();
  const isLoading = !transcript || isLoadingTranscript || isWaitingForResponse;
  const formik = useFormik({
    initialValues: {
      text: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { text } = values;
      onPostMessage({ text });
      formik.resetForm();
    },
  });
  const isDisabled =
    formik.values.text.length > MAX_TEXT_LENGTH || formik.values.text === '' || isLoading;

  const __onSubmit = useUpdatingCallback(({ event }) => {
    formik.handleSubmit(event);
  });

  const __onKeyDown = useUpdatingCallback((evt) => {
    if (evt.key === 'Enter' && !isDisabled) {
      formik.handleSubmit(evt);
    }
  });

  const __onChange = useUpdatingCallback((evt) => {
    formik.setFieldValue('text', evt.target.value.replace('\n', ''));
  });

  return (
    <form id='chatFeed' onSubmit={__onSubmit} noValidate>
      <div css={{ width: '100%', height: 1, background: 'grey' }} />
      <div
        css={{
          padding: 12,
          border: 'solid 1px grey',
          margin: 12,
          borderRadius: 8,
          '& textarea': {
            border: 'none !important',
            outline: 'none  !important',
            webkitBoxShadow: 'none  !important',
            mozBoxShadow: 'none  !important',
            boxShadow: 'none  !important',
          },
        }}
      >
        <TextareaAutosize
          name='text'
          onKeyDown={__onKeyDown}
          css={{
            width: '100%',
            minHeight: 45,
            fontSize: 16,
            resize: 'none',
            color: 'white',
            background: '#2d2d2d',
            fontFamily: 'Inter, sans-serif',
            ...extraTextAreaCSS,
          }}
          placeholder='Ask any question about the video...'
          value={formik.values.text}
          onChange={__onChange}
          autoComplete='off'
        />
        <__ErrorMessage formik={formik} />

        <div
          css={{
            display: 'flex',
            flex: 1,
            width: '100%',
            flexFlow: 'row nowrap',
            justifyContent: 'space-between',
          }}
        >
          <div css={{ display: 'flex', flex: 1, width: '100%' }}>{leftActionButtons}</div>
          <div css={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center' }}>
            <__LengthCount formik={formik} />
            <BasicButton
              onClick={__onSubmit}
              disabled={isDisabled}
              extraCSS={{ background: 'hsl(272deg 75% 65%)' }}
              text='Send'
            />
          </div>
        </div>
      </div>
    </form>
  );
};

const __ErrorMessage = ({ formik }) => {
  const showError = formik.touched.text && Boolean(formik.errors.text);

  return (
    <div css={{ marginTop: 4, marginLeft: 4, color: colors.const_red, fontSize: 11 }}>
      {showError && formik.errors.text}
    </div>
  );
};

const MAX_TEXT_LENGTH = 140;

const __LengthCount = ({ formik }) => {
  const value = formik.values.text;

  if (!value) return null;

  const isOver = value.length > MAX_TEXT_LENGTH;

  return (
    <div
      css={{
        fontSize: 12,
        color: isOver ? colors.const_red : colors.s_text,
        width: 'auto',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginRight: 8,
        minHeight: 24,
        zIndex: 2,
      }}
    >
      <span css={{ marginRight: 2 }}>{value.length}</span>
      <span css={{ marginRight: 2 }}>/</span>
      <span>{MAX_TEXT_LENGTH}</span>
    </div>
  );
};

const validationSchema = yup.object({
  text: yup
    .string()
    .required()
    .max(MAX_TEXT_LENGTH, `Question cannot be longer than ${MAX_TEXT_LENGTH} characters`),
});

export default ChatFeedForm;
