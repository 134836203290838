import { useRef, useState } from 'react';

const useStopWatch = () => {
  const [seconds, setTimer] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const countRef = useRef(null);

  const start = () => {
    setIsActive(true);
    setIsPaused(false);
    // @ts-ignore
    countRef.current = setInterval(() => {
      setTimer((_seconds) => _seconds + 1);
    }, 1000);
  };

  const pause = () => {
    // @ts-ignore
    clearInterval(countRef.current);
    setIsPaused(true);
  };

  const resume = () => {
    setIsPaused(false);
    // @ts-ignore
    countRef.current = setInterval(() => {
      setTimer((_seconds) => _seconds + 1);
    }, 1);
  };

  const reset = () => {
    // @ts-ignore
    clearInterval(countRef.current);
    setIsActive(false);
    setIsPaused(false);
    setTimer(0);
  };

  return { seconds, isActive, isPaused, start, pause, resume, reset };
};

export default useStopWatch;
