import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { isMobile } from 'react-device-detect';
import { GoogleGIcon, DiscordIcon, TwitterIcon } from 'Icons';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import Text from 'components/Text';
import AuthDS, { useAuth } from 'data/AuthDS';
import useUser from 'contexts/userContext';
import isDemoVideo from 'utils/isDemoVideo';
import isValidYouTubeURL from 'utils/isValidYouTubeURL';

const SignUpModal = ({
  isOpen = null,
  setIsOpen,
}: {
  isOpen?: boolean | null;
  setIsOpen?: Function;
}) => {
  const { userId } = useUser();
  const isAuthModalOpen = useAuth({ transform: (x) => x?.isAuthModalOpen });
  const [canCloseModal, setCanCloseModal] = useState(true);
  const location = useLocation();

  const handleClose = useUpdatingCallback(() => {
    if (!canCloseModal) {
      return;
    }

    window.sessionStorage.removeItem('videoId');
    AuthDS.get().closeAuthModal();

    if (setIsOpen) {
      setIsOpen(false);
    }
  });

  useEffect(() => {
    if (userId) {
      AuthDS.get().closeAuthModal();
    }
  }, [userId]);

  useEffect(() => {
    const videoId = location.pathname.slice(1);

    if (videoId && !isDemoVideo({ videoId }) && !userId) {
      setCanCloseModal(false);
      return;
    }
  }, [location, userId]);

  return (
    <Modal
      open={isOpen !== null ? isOpen : isAuthModalOpen}
      onClose={handleClose}
      css={{ outline: 'none' }}
    >
      <div
        css={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          width: isMobile ? 'calc(100vw - 10px)' : 400,
          borderRadius: 8,
          background: 'rgba(32, 33, 35)',
          padding: 24,
          outline: 'none',
        }}
      >
        <__Header showReturnToDemo={!canCloseModal} />
        <div
          css={{
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginTop: 40,
          }}
        >
          <___ConnectAccountButton href='/auth/google/login' Icon={<GoogleGIcon />} text='Google' />
          <___ConnectAccountButton
            href='/auth/discord/login'
            Icon={<DiscordIcon />}
            text='Discord'
          />
        </div>
      </div>
    </Modal>
  );
};

const __Header = ({ showReturnToDemo }: { showReturnToDemo: boolean }) => {
  const onGoToDemo = useUpdatingCallback(() => {
    window.sessionStorage.removeItem('videoId');
  });
  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      <div
        css={{
          lineHeight: '24px',
          fontWeight: 600,
          fontSize: 16,
          color: 'rgb(217, 217, 227)',
          marginBottom: 12,
          flex: 1,
          pointerEvents: 'none',
        }}
      >
        Sign-up or Log-in
      </div>
      <div
        css={{
          lineHeight: '24px',
          fontWeight: 500,
          fontSize: 16,
          color: 'rgb(217, 217, 227)',
          marginBottom: 12,
          flex: 1,
          pointerEvents: 'none',
          opacity: 0.7,
        }}
      >
        Create an account or log-in to your existing account to upload your own videos.
      </div>
      {showReturnToDemo && (
        <div
          css={{
            lineHeight: '24px',
            fontWeight: 500,
            fontSize: 16,
            color: 'rgb(217, 217, 227)',
            marginBottom: 12,
            flex: 1,
            opacity: 0.7,
            cursor: 'default',
          }}
        >
          Go back to the{' '}
          <a href='/' css={{ color: 'rgb(217, 217, 227)', cursor: 'pointer' }} onClick={onGoToDemo}>
            demo
          </a>
        </div>
      )}
    </div>
  );
};

const ___ConnectAccountButton = ({
  href,
  Icon,
  text,
}: {
  href: string;
  Icon: any;
  text: string;
}) => {
  // const navigate = useNavigate();

  const __handleClick = useUpdatingCallback((evt) => {
    let videoId = AuthDS.get().getRedirectVideoId() || window.location.pathname.slice(1);

    if (videoId) {
      let url = 'https://youtu.be/' + videoId;

      if (isValidYouTubeURL(url)) {
        window.sessionStorage.setItem('videoId', videoId);
      }
    }

    // navigate(`${process.env.REACT_APP_API_ENDPOINT}${href}`);
  });

  return (
    <a
      onClick={__handleClick}
      href={href}
      css={{
        borderRadius: 8,
        border: '1px solid white',
        padding: 2,
        width: 230,
        height: 40,
        fontSize: 24,
        cursor: 'pointer',
        '&:hover, &:focus': {
          filter: 'brightness(85%)',
        },
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        textDecoration: 'none',
        outline: 'none !important',
        paddingLeft: 16,
        paddingRight: 16,
        marginBottom: 12,
        minWidth: 230,
      }}
    >
      {Icon}
      <Text
        ellipsifyLines={1}
        extraCSS={{
          fontSize: 15,
          fontWeight: 600,
          lineHeight: '24px',
          margin: 0,
          padding: 0,
          marginLeft: 8,
          color: 'white',
          alignItems: 'center',
        }}
      >
        Continue with {text}
      </Text>
    </a>
  );
};

export default SignUpModal;
