import * as Sentry from '@sentry/react';

function parseTimestamp(token: string): { start: number | undefined; end: number | undefined } {
  const cleanedToken = token.replace(/\(|\)|\[|\]/g, '');
  let [start, end]: [string | undefined, string | undefined] = [undefined, undefined];
  if (cleanedToken.includes(':')) {
    [start, end] = cleanedToken.split(':');
  } else {
    const [num_1 = 0, num_2 = 0, num_3 = 0, num_4 = 0] = cleanedToken.split('.');
    start = `${num_1}.${num_2}`;
    end = `${num_3}.${num_4}`;
  }

  if (start == undefined || end == undefined) {
    Sentry.withScope((scope) => {
      const data = {
        token,
        start,
        end,
      };
      scope.setExtra('data', data);
      Sentry.captureException(new Error(`Invalid timestamp: ${token}`));
    });
  }

  return { start: start ? parseFloat(start) : undefined, end: end ? parseFloat(end) : undefined };
}

export default parseTimestamp;
