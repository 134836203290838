import React, { useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ChatFeed from 'components/ChatFeed';
import { useTranscript } from 'contexts/transcriptContext';
import VideoAndTranscript from 'components/VideoAndTranscript';
import { isMobile } from 'react-device-detect';
import MobileVideoPage from './MobileVideoPage';
import isValidYouTubeURL from 'utils/isValidYouTubeURL';
import useUser from 'contexts/userContext';
import ReactPlayer from 'react-player/youtube';
import Dimensions from 'utils/Dimensions';
import isDemoVideoId from 'utils/isDemoVideo';
import CallAPI from 'utils/CallAPI';
import AuthDS from 'data/AuthDS';

const VideoPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { videoURL, setVideoURL, setIsLoadingVideo } = useTranscript();
  const playerRef = useRef<ReactPlayer>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const { userId, anonVisitorId } = useUser();

  useEffect(() => {
    const email = localStorage.getItem('email');
    const didAddEmailAfterServerDeployment = localStorage.getItem('didSendDiscordEmailNotif');
    if (userId && email && didAddEmailAfterServerDeployment !== 'TRUE') {
      const addEmail = async () => {
        localStorage.setItem('didSendDiscordEmailNotif', 'TRUE');
        await CallAPI.addEmail({ email });
      };
      addEmail();
    }
  }, [userId]);

  useEffect(() => {
    if (!location) return;

    const videoId = location.pathname.slice(1);
    const isUserLoaded = !!userId || !!anonVisitorId;
    const isDemoVideo = isDemoVideoId({ videoId });
    let url = 'https://youtu.be/' + videoId;

    if (isDemoVideo && isUserLoaded) {
      setVideoURL(url);
      setIsLoadingVideo(true);
    }

    if (!isDemoVideo && !userId) {
      AuthDS.get().openAuthModal();
      setVideoURL(url);
      setIsLoadingVideo(true);
    }

    if (!isDemoVideo && userId) {
      if (isValidYouTubeURL(url)) {
        setVideoURL(url);
        setIsLoadingVideo(true);
      } else {
        navigate('/');
      }
    }
  }, [location, userId, anonVisitorId]);

  if (!videoURL) {
    return null;
  }

  if (isMobile) {
    return (
      <MobileVideoPage playerRef={playerRef} isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
    );
  }

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        height: `calc(100vh - ${Dimensions.HEADER_HEIGHT}px)`,
        justifyContent: 'center',
      }}
    >
      <VideoAndTranscript playerRef={playerRef} isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
      <ChatFeed playerRef={playerRef} setIsPlaying={setIsPlaying} />
    </div>
  );
};

export default VideoPage;
