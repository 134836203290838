import { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { load } from '@fingerprintjs/fingerprintjs';
import * as Sentry from '@sentry/react';
import CallAPI from 'utils/CallAPI';
import AuthDS, { useAuth } from 'data/AuthDS';
import ReactGA from 'react-ga4';

type UserContextType = {
  userId: string;
  anonVisitorId: string;
  genLogout: Function;
};

export const UserContext = createContext<UserContextType>({
  userId: '',
  anonVisitorId: '',
  genLogout: () => {},
});

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  // const [userId, setUserId] = useState('');
  const [anonVisitorId, setAnonVisitorId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const userId = useAuth({ transform: (x) => x?.userId });

  useEffect(() => {
    const accessToken = searchParams.get('accessToken');
    if (accessToken) {
      window.localStorage.setItem('accessToken', accessToken);
      searchParams.delete('accessToken');
      setSearchParams(searchParams);
    }
  }, [location.pathname]);

  useEffect(() => {
    __genGetCurrentSession({ setAnonVisitorId, setIsLoading, setIsError });
  }, []);

  async function genLogout(): Promise<void> {
    const accessToken = window.localStorage.getItem('accessToken') || '';
    window.localStorage.removeItem('accessToken');
    await CallAPI.logout({ accessToken });
    navigate('/');
    AuthDS.get().setUserId('');
  }

  const memoedValues = useMemo(
    () => ({ userId, genLogout, anonVisitorId }),
    [userId, genLogout, anonVisitorId],
  );

  return <UserContext.Provider value={memoedValues}>{!isLoading && children}</UserContext.Provider>;
};

export default function useUser(): UserContextType {
  return useContext(UserContext);
}

async function __genGetCurrentSession({
  setAnonVisitorId,
  setIsLoading,
  setIsError,
}: {
  setAnonVisitorId: (val: string) => void;
  setIsLoading: (val: boolean) => void;
  setIsError: (val: boolean) => void;
}): Promise<void> {
  try {
    const response = await CallAPI.getCurrentSession();

    if (response && response.data && response.data.result.userId) {
      AuthDS.get().setUserId(response.data.result.userId);
      ReactGA.initialize('G-Q4L5E1HRL1', {
        gaOptions: {
          userId: response.data.result.userId,
        },
      });
    } else {
      // Initialize an agent at application startup.
      const fpPromise = load();
      // Get the visitor identifier when you need it.
      const fp = await fpPromise;
      const result = await fp.get();
      const userID = result.visitorId;
      Sentry.setUser({ id: userID, name: userID });
      setAnonVisitorId(userID);
      localStorage.setItem('anonVisitorId', userID);
    }
  } catch (err) {
    Sentry.captureException(err);
    setIsError(true);
  } finally {
    setIsLoading(false);
  }
}
