import React, { useState } from 'react';
import ReactPlayer from 'react-player/youtube';
import { useTranscript } from 'contexts/transcriptContext';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import Player from 'types/Player';

const VideoPlayer = ({
  playerRef,
  isPlaying,
  setIsPlaying,
}: {
  playerRef: Player;
  isPlaying: boolean;
  setIsPlaying: Function;
}) => {
  const { videoURL } = useTranscript();
  const [currentTime, setCurrentTime] = useState(0);

  const onPlay = useUpdatingCallback(() => setIsPlaying(true));
  const onPause = useUpdatingCallback(() => setIsPlaying(false));
  const onProgress = useUpdatingCallback((state) => {
    const newTime = state.playedSeconds.toFixed(0);
    setCurrentTime(parseFloat(newTime));
    const segment = document.querySelector(`[data-start-time^="${newTime}."]`);
    segment?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  });

  return (
    <div className='player-wrapper' css={{ height: '100%' }}>
      <style scoped>
        {`span[data-type^="transcript-word"][id^="${currentTime}."] {
                background-color: lightblue !important;
              },
          `}
      </style>
      <ReactPlayer
        progressInterval={500}
        onProgress={onProgress}
        onPlay={onPlay}
        onPause={onPause}
        playing={isPlaying}
        ref={playerRef}
        url={videoURL}
        className='react-player'
        width='100%'
        height='100%'
        controls={true}
      />
    </div>
  );
};

export default VideoPlayer;
