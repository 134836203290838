import React, { ChangeEventHandler, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Modal from '@mui/material/Modal';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { UpvoteIcon, DownvoteIcon } from 'Icons';
import Feedback from 'types/Feedback';
import { MessageType } from 'types/Message';
import colors from 'colors';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import CallAPI from 'utils/CallAPI';
import { isMobile } from 'react-device-detect';

const FeedbackButtons = ({
  videoId,
  messageType,
  modelOutput,
  userInput,
}: {
  videoId: string;
  messageType: MessageType;
  modelOutput: string;
  userInput?: string;
}) => {
  const [canSubmit, setCanSubmit] = useState(true);

  const __onSubmit = () => setCanSubmit(false);

  return (
    <div
      css={{
        display: 'flex',
        flexFlow: 'row nowrap',
        gap: 4,
        padding: 4,
        paddingTop: 0,
        marginLeft: 'auto',
      }}
    >
      <__FeedbackButton
        canSubmit={canSubmit}
        onSubmit={__onSubmit}
        feedback={{ videoId, isPositive: true, inferenceType: messageType, modelOutput, userInput }}
      />
      <__FeedbackButton
        canSubmit={canSubmit}
        onSubmit={__onSubmit}
        feedback={{
          videoId,
          isPositive: false,
          inferenceType: messageType,
          modelOutput,
          userInput,
        }}
      />
    </div>
  );
};

const __FeedbackButton = ({
  canSubmit,
  onSubmit,
  feedback,
}: {
  canSubmit: boolean;
  onSubmit: Function;
  feedback: Feedback;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  return (
    <>
      <button
        onClick={() => {
          if (canSubmit) {
            setIsSelected(true);
            setIsDialogOpen(true);
          }
        }}
        css={{
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'center',
          justifyContent: 'center',
          width: 32,
          height: 32,
          color: colors.const_grey,
          border: 'none',
          background: 'none',
          padding: 2,

          ...(canSubmit
            ? {
                cursor: 'pointer',
                '&:hover': {
                  color: colors.const_white,
                  backgroundColor: 'rgb(64, 65, 79)',
                  borderRadius: 4,
                },
              }
            : {
                cursor: 'default',
              }),

          ...(isSelected && {
            color: feedback.isPositive ? colors.const_green : colors.const_red,
          }),
        }}
      >
        {feedback.isPositive ? (
          <UpvoteIcon height='1.2em' width='1.2em' />
        ) : (
          <DownvoteIcon height='1.2em' width='1.2em' />
        )}
      </button>
      <__FeedbackForm
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        onSubmit={onSubmit}
        feedback={feedback}
      />
    </>
  );
};

const __FeedbackForm = ({
  isOpen,
  setIsOpen,
  onSubmit,
  feedback,
}: {
  isOpen: boolean;
  setIsOpen: Function;
  onSubmit: Function;
  feedback: Feedback;
}) => {
  const formik = useFormik({
    initialValues: {
      userFeedback: '',
      isPositive: feedback.isPositive,
      isHarmful: false,
      isFalse: false,
      isNotHelpful: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { userFeedback, isHarmful, isFalse, isNotHelpful } = values;
      __genSubmitFeedback({
        feedback: {
          ...feedback,
          feedback: userFeedback,
          ...(!feedback.isPositive && {
            isHarmful,
            isFalse,
            isNotHelpful,
          }),
        },
      });
      formik.resetForm();
      setIsOpen(false);
      onSubmit();
    },
  });

  const __onSubmit = useUpdatingCallback((evt) => {
    evt.preventDefault();
    formik.handleSubmit(evt);
  });

  return (
    <Modal
      open={isOpen}
      onClose={__onSubmit}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <form
        id='postFeedback'
        onSubmit={__onSubmit}
        noValidate
        css={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexFlow: 'column nowrap',
          width: isMobile ? 'calc(100vw - 10px)' : 512,
          height: feedback.isPositive ? 258 : 330,
          borderRadius: 8,
          background: 'rgba(32, 33, 35)',
          padding: 24,
        }}
      >
        <div css={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center' }}>
          <div
            css={{
              display: 'flex',
              flexFlow: 'column nowrap',
              alignItems: 'center',
              justifyContent: 'center',
              width: 40,
              height: 40,
              borderRadius: '50%',
              background: feedback.isPositive ? 'rgb(210, 244, 211)' : 'rgb(254, 226, 226)',
              color: feedback.isPositive ? 'rgb(26, 127, 100)' : 'rgb(220, 38, 38)',
            }}
          >
            {feedback.isPositive ? (
              <UpvoteIcon height='1.5em' width='1.5em' />
            ) : (
              <DownvoteIcon height='1.5em' width='1.5em' />
            )}
          </div>
          <div
            css={{
              lineHeight: '24px',
              fontWeight: 700,
              fontSize: 18,
              color: 'rgb(217, 217, 227)',
              marginLeft: 12,
            }}
          >
            Provide additional feedback
          </div>
        </div>
        <TextareaAutosize
          id='userFeedback'
          name='userFeedback'
          css={{
            width: '100%',
            minHeight: 100,
            resize: 'none',
            color: 'white',
            background: 'rgb(52, 53, 65)',
            borderRadius: 8,
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'rgb(142, 142, 160)',
            paddingLeft: 12,
            paddingRight: 12,
            paddingTop: 8,
            paddingBottom: 8,
            fontFamily: 'inherit',
            marginTop: 16,
            '&:focus': {
              outline: 'none !important',
              border: '1px solid white',
              boxShadow: '0 0 1px white',
            },
          }}
          placeholder='How can we improve this answer?'
          value={formik.values.userFeedback}
          onChange={formik.handleChange}
          autoComplete='off'
        />
        {!feedback.isPositive && (
          <div css={{ marginTop: 16, color: 'rgb(217, 217, 227)' }}>
            <_Checkbox
              id='isHarmful'
              name='isHarmful'
              value={formik.values.isHarmful}
              labelText='This is harmful / unsafe'
              onChange={formik.handleChange}
            />
            <_Checkbox
              id='isFalse'
              name='isFalse'
              value={formik.values.isFalse}
              labelText='This is false / incorrect'
              onChange={formik.handleChange}
            />
            <_Checkbox
              id='isNotHelpful'
              name='isNotHelpful'
              value={formik.values.isNotHelpful}
              labelText='This is unclear / not helpful'
              onChange={formik.handleChange}
            />
          </div>
        )}
        <__ErrorMessage formik={formik} field='userFeedback' />
        <button
          form='postFeedback'
          css={{
            borderRadius: 8,
            padding: 6,
            background: 'rgb(52, 53, 65)',
            borderColor: 'rgb(86, 88, 105)',
            borderStyle: 'solid',
            borderWidth: '1px',
            color: 'rgb(217, 217, 227)',
            paddingLeft: 12,
            paddingRight: 12,
            minHeight: 32,
            height: 32,
            display: 'flex',
            alignItems: 'center',
            marginTop: feedback.isPositive ? 16 : 'auto',
            cursor: 'pointer',
            alignSelf: 'flex-end',

            '&:hover': {
              backgroundColor: 'rgb(64, 65, 79)',
            },
          }}
          type='submit'
        >
          Submit feedback
        </button>
      </form>
    </Modal>
  );
};

const _Checkbox = ({
  name,
  value,
  id,
  labelText,
  onChange,
}: {
  name: string;
  value: any;
  id: string;
  labelText: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}) => {
  return (
    <div>
      <input type='checkbox' id={id} name={name} value={value} onChange={onChange} />
      <label css={{ marginLeft: 8 }} htmlFor={id}>
        {labelText}
      </label>
    </div>
  );
};

const __ErrorMessage = ({ formik, field }) => {
  const showError = formik.touched[field] && Boolean(formik.errors[field]);

  return (
    <div css={{ marginTop: 4, marginLeft: 4, color: colors.const_red, fontSize: 11 }}>
      {showError && formik.errors[field]}
    </div>
  );
};

const MAX_FEEDBACK_LENGTH = 10;

const validationSchema = yup.object({
  url: yup
    .string()
    .max(MAX_FEEDBACK_LENGTH, `Feedback cannot be longer than ${MAX_FEEDBACK_LENGTH} characters`),
  isHarmful: yup.boolean(),
  isFalse: yup.boolean(),
  isNotHelpful: yup.boolean(),
});

async function __genSubmitFeedback({ feedback }: { feedback: Feedback }): Promise<void> {
  await CallAPI.postFeedback({ feedback });
}

export default FeedbackButtons;
