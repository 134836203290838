import React from 'react';
import { TIMESTAMP_REGEX } from 'components/ChatQuiz';
import Player from 'types/Player';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import parseTimestamp from 'utils/parseTimestamp';

const TextWithClickableTimestamp = ({
  text,
  playerRef,
  setIsPlaying,
  extraCSS = {},
}: {
  text: string;
  playerRef?: Player;
  setIsPlaying?: Function;
  extraCSS?: any;
}) => {
  const paragraphs = text.split('\n').filter((x) => !!x);

  const formattedParagraphs = paragraphs.map((paragraph, i) => {
    const allTokens = paragraph.split(' ');
    return [
      ...allTokens.map((token, i) => {
        token = token.replace('[START:END]', '');
        if (TIMESTAMP_REGEX.test(token)) {
          return (
            <__Ref
              key={`ts-${i}-${token}`}
              token={token}
              index={i}
              numOfTokens={allTokens.length}
              playerRef={playerRef}
              setIsPlaying={setIsPlaying}
            />
          );
        }
        return (
          <span key={`ts-${i}`}>
            {token}
            {i < allTokens.length - 1 ? ' ' : ''}
          </span>
        );
      }),
      i === paragraphs.length - 1 ? null : [<br />, <br />],
    ];
  });

  return (
    <div
      css={{
        '& #timestamp:hover': { textDecoration: 'underline' },
        color: 'white',
        ...extraCSS,
      }}
    >
      {formattedParagraphs}
    </div>
  );
};

const __Ref = ({
  token,
  index,
  numOfTokens,
  playerRef,
  setIsPlaying,
}: {
  token: string;
  index: number;
  numOfTokens: number;
  playerRef?: Player;
  setIsPlaying?: Function;
}) => {
  const lastStr = token[token.length - 1];
  const { start } = parseTimestamp(token);
  const onClick = useUpdatingCallback(() => {
    if (start === undefined) return;
    const segment = document.querySelector(`[data-start-time^="${start}."]`);
    segment?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    playerRef?.current?.seekTo(start);
    setIsPlaying && setIsPlaying(true);
  });
  // skip timestamp
  if (start === undefined) {
    return null;
  }
  return (
    <span key={`ts-${index}`}>
      <span
        id='timestamp'
        onClick={onClick}
        css={{
          color: 'lightblue',
          cursor: 'pointer',
        }}
      >
        [ref {start.toFixed(0)}s]
      </span>
      {lastStr !== ']' ? lastStr : ''}
      {index < numOfTokens - 1 ? ' ' : ''}
    </span>
  );
};

export default React.memo(TextWithClickableTimestamp);
