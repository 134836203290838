import React, { useState, useEffect, useRef } from 'react';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import getVideoId from 'get-video-id';
import demoVideos, { DemoVideo } from 'utils/demoVideos';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import Message from 'types/Message';
import { GPTSvg } from 'SVGs';
import { TIMESTAMP_REGEX } from 'components/ChatQuiz';
import Typewriter from 'typewriter-effect';
import TextWithClickableTimestamp from 'components/TextWithClickableTimestamp';
import ChatFeedForm from 'components/ChatFeedForm';
import BasicButton from 'components/BasicButton';
import { isMobile } from 'react-device-detect';
import parseTimestamp from 'utils/parseTimestamp';

const animatedMessageIDs: string[] = [];

const Demo = () => {
  const [selectedVideoIndex, setSelectedVideoIndex] = useState<number>(0);

  // On mobile, only get 2 examples
  const exampleVideos = isMobile ? demoVideos.slice(0, 2) : demoVideos;
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: 32,
        marginBottom: isMobile ? 0 : 60 + 24,
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: isMobile ? '' : 32,
          padding: isMobile ? 5 : '',
          alignItems: 'center',
          width: isMobile ? '100vw' : '',
          justifyContent: 'center',
          cursor: 'default',
        }}
      >
        {exampleVideos.map((videoData, i) => (
          <__VideoListItem
            key={videoData.url}
            videoData={videoData}
            setSelectedVideoIndex={setSelectedVideoIndex}
            isFocused={selectedVideoIndex === i}
            isLast={i === demoVideos.length - 1}
            videoIndex={i}
          />
        ))}
      </div>
      {isMobile ? null : <__ChatFeed selectedVideoIndex={selectedVideoIndex} />}
    </div>
  );
};

const __ChatFeed = ({ selectedVideoIndex }: { selectedVideoIndex: number }) => {
  const [chatFeedMessagesByVideoIndex, setChatMessagedByVideoIndex] = useState<{
    [num: number]: Message[];
  }>({});

  __useScheduleSendMessages({
    chatFeedMessagesByVideoIndex,
    setChatMessagedByVideoIndex,
    selectedVideoIndex,
  });

  const navigate = useNavigate();
  const handleOnClick = useUpdatingCallback((evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    sessionStorage.setItem(
      'messages',
      JSON.stringify([
        {
          user: 'CHATGPT',
          text: `I'm summarizing "${demoVideos[selectedVideoIndex].title}"`,
          id: uuidv4(),
          type: 'summary',
        },
        {
          user: 'CHATGPT',
          text: demoVideos[selectedVideoIndex].summary,
          id: uuidv4(),
          type: 'summary',
        },
      ]),
    );
    const { id: videoId } = getVideoId(demoVideos[selectedVideoIndex].url);
    navigate(`/${videoId}`);
  });
  const chatFeedMessages = chatFeedMessagesByVideoIndex[selectedVideoIndex] || [];

  return (
    <div
      onClick={handleOnClick}
      css={{
        width: 500,
        height: 418,
        background: '#2d2d2d',
        borderRadius: 12,
        position: 'relative',
        '&:hover .overlay': { visibility: 'visible' },
        '& .overlay': { visibility: 'hidden' },
        cursor: 'pointer',
      }}
    >
      <div
        className='overlay'
        css={{
          width: 500,
          height: 400,
          background: '#2d2d2d',
          borderRadius: 12,
          cursor: 'pointer',
          zIndex: 1,
          position: 'absolute',
          left: 0,
          top: 0,
          opacity: 0.4,
          pointerEvents: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
      <div
        className='overlay'
        css={{
          padding: 6,
          paddingLeft: 16,
          paddingRight: 16,
          background: 'rgba(255, 255, 255, 0.9)',
          borderRadius: 100,
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          position: 'absolute',
        }}
      >
        <div css={{ fontSize: 20, color: 'black' }}>Click to interact</div>
      </div>
      <div
        css={{
          overflow: 'scroll',
          height: 293,
          maxHeight: 293,
          minHeight: 293,
          pointerEvents: 'none',
        }}
      >
        {chatFeedMessages?.map((message, i) => (
          <__ChatMessage message={message} key={message.id} isFirst={i === 0} />
        ))}
      </div>
      <div css={{ pointerEvents: 'none' }}>
        <ChatFeedForm
          onPostMessage={() => {}}
          leftActionButtons={[
            <BasicButton key='quiz' text='Quiz me' onClick={() => {}} disabled={false} />,
            <BasicButton
              key='summary'
              text='Summarize'
              onClick={() => {}}
              disabled={false}
              extraCSS={{ marginLeft: 6 }}
            />,
          ]}
          extraTextAreaCSS={{ minHeight: 30, height: 30, maxHeight: 30 }}
        />
      </div>
    </div>
  );
};

const __ChatMessage = ({ message, isFirst }: { message: Message; isFirst: boolean }) => {
  const { id, text } = message;
  const allTokens = text.split(' ');
  const transformedText = allTokens
    .map((token, i) => {
      const spacer = i < allTokens.length - 1 ? ' ' : '';
      if (TIMESTAMP_REGEX.test(token)) {
        const { start } = parseTimestamp(token);
        if (start) {
          return `<span style="color: lightblue; cursor: pointer">[ref ${start.toFixed(
            0,
          )}s]</span>${spacer}`;
        } else {
          return `${token}${spacer}`;
        }
      }
      // skip timestamp
      return `${token}${spacer}`;
    })
    .join('');

  return (
    <div
      key={id}
      css={{
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 24,
        paddingRight: 24,
        fontSize: 16,
        marginBottom: 6,
        width: '100%',
        marginTop: isFirst ? 24 : 10,
      }}
    >
      <div
        css={{
          display: 'flex',
          flexFlow: 'row nowrap',
          color: 'white',
          fontSize: 16,
          lineHeight: '24px',
        }}
      >
        <div css={{ marginRight: 12 }}>{GPTSvg}</div>
        {animatedMessageIDs.includes(id) ? (
          <TextWithClickableTimestamp text={text} />
        ) : (
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .typeString(transformedText)
                .start()
                .callFunction(() => animatedMessageIDs.push(id));
            }}
            options={{
              autoStart: true,
              loop: false,
              delay: 10,
              cursor: '',
            }}
          />
        )}
      </div>
    </div>
  );
};

const __addMessage = ({
  videoIndex,
  message,
  setChatMessagedByVideoIndex,
}: {
  videoIndex: number;
  message: Message;
  setChatMessagedByVideoIndex: Function;
}) => {
  setChatMessagedByVideoIndex((x) => {
    return {
      ...x,
      [videoIndex]: [...(x[videoIndex] || []), message],
    };
  });
};

const __VideoListItem = ({
  videoData,
  setSelectedVideoIndex,
  isFocused,
  isLast,
  videoIndex,
}: {
  videoData: DemoVideo;
  setSelectedVideoIndex: Function;
  isFocused: boolean;
  isLast: boolean;
  videoIndex: number;
}) => {
  const imageURL = __getVideoThumbnailURL(videoData.url);

  const handleMouseEnter = useUpdatingCallback(() => {
    setSelectedVideoIndex(videoIndex);
  });
  const onClick = useUpdatingCallback((evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const { id: videoId } = getVideoId(demoVideos[videoIndex].url);
    if (!isMobile) {
      sessionStorage.setItem(
        'messages',
        JSON.stringify([
          {
            user: 'CHATGPT',
            text: `I'm summarizing "${demoVideos[videoIndex].title}"`,
            id: uuidv4(),
            type: 'summary',
          },
          {
            user: 'CHATGPT',
            text: demoVideos[videoIndex].summary,
            id: uuidv4(),
            type: 'summary',
          },
        ]),
      );
    }
    navigate(`/${videoId}`);
  });
  const navigate = useNavigate();
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onClick={onClick}
      css={{
        display: 'flex',
        flexDirection: 'row',
        width: isMobile ? '' : 570,
        marginBottom: isLast ? 0 : 14,
        borderRadius: 11,
        boxShadow: isFocused ? '0 0 0 1px rgba(45,45,45,0.5)' : '0 0 0 1px rgba(45,45,45,0.1)',
        backdropFilter: 'sepia(15%)',
        cursor: 'pointer',
      }}
    >
      {
        // make it harder to focus on another card by mistake
        isFocused && !isMobile && (
          <div
            css={{
              background: 'transparent',
              position: 'absolute',
              right: -30,
              top: -150,
              width: 100,
              height: 430,
              zIndex: 1,
            }}
          />
        )
      }
      <div
        css={{
          height: isMobile ? 105 : 128,
          width: isMobile ? 150 : 200,
          minHeight: isMobile ? 100 : 130,
          minWidth: isMobile ? 150 : 200,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          borderRadius: 11,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <img
          alt={videoData.title}
          css={{ height: isMobile ? 140 : 174, width: 'auto' }}
          src={imageURL}
        />
      </div>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: 12,
          paddingRight: 6,
          color: '#F0F0F0',
          paddingTop: 6,
        }}
      >
        <div css={{ fontWeight: 550, fontSize: isMobile ? 14 : 18 }}>{videoData.title}</div>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 12,
          }}
        >
          <img
            alt={videoData.channelName}
            css={{
              height: 20,
              width: 20,
              borderRadius: 10,
              fontSize: isMobile ? 10 : 14,
              marginRight: 8,
            }}
            src={videoData.channelPhotoURL}
          />
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              fontWeight: 500,
              fontSize: isMobile ? 10 : 14,
              color: '#F0F0F0',
              opacity: 0.8,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            <div>{videoData.channelName}</div>
            <div
              css={{
                marginRight: isMobile ? 6 : 12,
                marginLeft: isMobile ? 6 : 12,
              }}
            >
              &middot;
            </div>
            <div>{videoData.numViews} views</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const __useScheduleSendMessages = ({
  chatFeedMessagesByVideoIndex,
  setChatMessagedByVideoIndex,
  selectedVideoIndex,
}: {
  chatFeedMessagesByVideoIndex: { [num: number]: Message[] };
  setChatMessagedByVideoIndex: Function;
  selectedVideoIndex: number;
}) => {
  const hoverTimer_one: any = useRef();

  useEffect(() => {
    hoverTimer_one.current && clearTimeout(hoverTimer_one.current);
    const videMessages = chatFeedMessagesByVideoIndex[selectedVideoIndex] || [];

    if (videMessages.length === 0) {
      hoverTimer_one.current = setTimeout(() => {
        __addMessage({
          setChatMessagedByVideoIndex,
          videoIndex: selectedVideoIndex,
          message: {
            user: 'CHATGPT',
            text: `I'm summarizing "${demoVideos[selectedVideoIndex].title}"`,
            id: uuidv4(),
            type: 'summary',
          },
        });
        setTimeout(() => {
          __addMessage({
            setChatMessagedByVideoIndex,
            videoIndex: selectedVideoIndex,
            message: {
              user: 'CHATGPT',
              text: `${demoVideos[selectedVideoIndex].summary.slice(0, 399)}...`,
              id: uuidv4(),
              type: 'summary',
            },
          });
        }, 2000);
      }, 400);
    }
  }, [demoVideos[selectedVideoIndex].url]);
};

function __getVideoThumbnailURL(videoURL: string): string {
  const videoID = videoURL.split('v=')[1];
  const ampersandPosition = videoID.indexOf('&');
  if (ampersandPosition !== -1) {
    return `https://img.youtube.com/vi/${videoID.substring(0, ampersandPosition)}/0.jpg`;
  }
  return `https://img.youtube.com/vi/${videoID}/0.jpg`;
}

export default Demo;
