import Message from 'types/Message';

export type DemoVideo = {
  videoId: string;
  url: string;
  title: string;
  channelName: string;
  channelPhotoURL: string;
  numViews: string;
  summary: string;
  demoChat?: Message[];
};

const demoVideos: DemoVideo[] = [
  {
    videoId: 'H14bBuluwB8',
    url: 'https://www.youtube.com/watch?v=H14bBuluwB8&t=1s',
    title: 'Grit: the power of passion and perseverance | Angela Lee Duckworth',
    channelName: 'TEDx Talks',
    channelPhotoURL:
      'https://yt3.ggpht.com/ytc/AMLnZu94pzTFiSDqYIvXn40JdctQCOxK2fnAMEy0zdL6kA=s900-c-k-c0x00ffffff-no-rj',
    numViews: '11M',
    summary: `The speaker left her demanding job in management consulting to teach seventh graders math in the New York City public schools ([13.04:16.76]), and found that IQ was not the only differentiating factor between her strongest and weakest students ([25.96:28.4]). She went on to study success in various challenging contexts and found that the most significant predictor of success was not IQ or physical health, but rather "grit" - passion and perseverance for long-term goals ([182.16000000000003:187.24]). She also studied high school juniors in the Chicago Public Schools and found that grittier students were more likely to graduate, even when controlling for family income and other factors ([215.68:218.84]). Carol Dweck's idea of "growth mindset" is a promising idea for building grit, but further testing is necessary ([299.64000000000004:302]).`,
  },
  {
    videoId: 'QmOF0crdyRU',
    url: 'https://www.youtube.com/watch?v=QmOF0crdyRU&t=1s',
    title: 'Controlling Your Dopamine For Motivation, Focus & Satisfaction',
    channelName: 'Andrew Huberman',
    channelPhotoURL:
      'https://yt3.ggpht.com/5ONImZvpa9_hYK12Xek2E2JLzRc732DWsZMX2F-AZ1cTutTQLBuAmcEtFwrCgypqJncl5HrV2w=s88-c-k-c0x00ffffff-no-rj',
    numViews: '4.2M',
    summary: `The Huberman Lab podcast discusses science and science-based tools for everyday life. Andrew Huberman, a professor of neurobiology and ophthalmology at Stanford School of Medicine, explains how dopamine drives motivation and desire, and can lead to addiction. He discusses the biology, psychology, neural circuits, and dopamine schedules [26.64:32.8]. Cold water exposure [182.24:189.04] can lead to very rapid increases in dopamine which can last a very long time [214.64:221.44]. He recommends ROCA eyewear, Inside Tracker, and Headspace to help optimize health and wellbeing [402.23999999999995:407.91999999999996]. He will also be speaking at Logitech's Rethink Education event on September 30th [594.72:600.5600000000001]. Dopamine is a neuromodulator that influences motivation, drive, craving, time perception, movement, and mood [638.64:674.56]. It works through two main neural circuits: the mesocorticolimbic pathway [674.56:684.56] and the nigrostriatal pathway [884.56:892.56]. These pathways are activated by local synaptic release of dopamine [892.56:907.36] and volumetric release [1044.8:1050.8799999999999], which can lead to an imbalance between baseline and peak levels [1050.8799999999999:1079.0400000000002].
    Dopamine is a neurotransmitter that is released in two ways [1320.72:1327.2800000000002]: through fast electrical synapses and through G protein-coupled receptors, which have slower effects [1338.96:1345.68]. It is responsible for movement, motivation, and drive, and can also influence gene expression and cell behavior [1353.3600000000001:1359.76]. It is also co-released with glutamate, an excitatory neurotransmitter that increases alertness [1373.12:1380.24]. Dopamine is a universal currency for tracking pleasure, success, and motivation [1581.76:1587.04], and its effects depend on the amount of dopamine compared to recent experiences [1687.52:1696]. It also has long-lasting effects, as illustrated by an incident in the 1980s in which an opioid-like compound caused a young population to become paralyzed and unable to move or show motivation [1892.4:1900.16]. The text discusses how dopamine levels can be increased through various activities, substances, and experiences [2052.7200000000003:2723.76]. Chocolate, sex, nicotine, cocaine, and amphetamine have been measured to increase dopamine 1.5, 2, 2.5, 2.5, and 10 times respectively above baseline [2479.84:2599.3599999999997]. Exercise can increase dopamine depending on the individual's enjoyment of the activity [2614:2635.6], and focusing on something one loves can also increase dopamine levels [2715.12:2718.1600000000003].
    Dopamine plays an important role in motivating us to seek rewards, whether it's food, shelter, social connection, or pleasure. [2723.76:2728.6400000000003] It is released during rewarding activities, but it drops below baseline afterwards. [2767.28:2773.68] This explains the phenomenon of diminishing returns when engaging in activities we enjoy, as our dopamine levels decrease the more we do it. [3273.28:3278.7200000000003] Caffeine can increase the number of dopamine receptors, allowing us to experience its effects more. [2829.12:2836.24] However, taking too many stimulants and trying to achieve the highest dopamine levels possible can have adverse effects. [2987.2:2993.7599999999998]
    The pleasure-pain balance of dopamine is dependent on the amount of dopamine that is readily available to be released at any given time [3338.48:3344.72]. When dopamine levels are increased through the consumption of substances or behaviors, the decrease in baseline afterward can lead to addiction, burnout, and depression [3344.72:3454.7200000000003]. To maintain healthy dopamine levels, it is important to understand the relationship between dopamine peaks and baselines, and to make choices that will not lead to excessive dopamine depletion [3455.76:3551.44]. One example of this is through abstaining from dopamine-inducing activities for a period of time [3551.44:3989.76].
    The transcript discusses how an individual was feeling depressed and thought they had ADHD, but their levels of concentration returned after their "video game social media fast" [4004.32:4008.6400000000003]. It explains that dopamine plays an important role in motivation, pleasure, and concentration [4027.6000000000004:4033.12], and suggests that engaging in activities with an intermittent reward schedule is the best way to keep dopamine levels up [4139.679999999999:4148.08]. This could include activities such as exercise, socializing, and even consuming certain foods [4103.12:4107.68], but not drugs of abuse [4082.56:4089.2]. The text also recommends that dopamine should not be expected or chased every time an activity is engaged in [4040.16:4044.56], and suggests that flipping a coin is a way to regulate dopamine levels [4474.48:4480.24] as this creates a random, rather than predictable, schedule [4433.92:4439.28]. Finally, the transcript mentions how overstimulation from digital technology can lead to a decrease in dopamine levels [4590.48:4596.
    I've made a rule for myself to not allow my phone into my workouts [4658.64:4665.76], as it can be distracting and deplete my dopamine levels over time [4790.08:4797.76]. Caffeine can increase the density and efficacy of dopamine receptors [5081.36:5087.28], and Yerba Mate has been shown to be neuroprotective specifically for dopaminergic neurons [5095.28:5101.679999999999]. However, caffeine can increase the toxicity of MDMA [5081.36:5087.28], and amphetamine and cocaine can cause long-term problems with dopaminergic pathways [5182.72:5188.64]. Thus, it is important to be cautious about spiking one's dopamine too much on a regular basis [5312.16:5318.639999999999].
    Cold water exposure has been found to increase dopamine levels by up to 2.5 times above baseline [5355.679999999999:5368.32] and can be a safe, zero-cost way of achieving long-term dopamine increases. [5400.48:5426.8] It is important to ensure safety when doing cold water exposure and to find the temperature that works best for the individual. [5444.24:5475.84] Studies have shown that cold water exposure can increase cortisol levels, but only transiently. [5671.2:5695.679999999999] People have different approaches to remaining in the cold such as relaxing, distracting, or energizing themselves. [5774.16:5793.68] Rewards for hard work can make it more challenging and can make people less likely to continue the activity. [5951.12:5976.24]
    The dopamine reward system can be used to create intrinsic motivation and build a growth mindset [5996.639999999999:6003.44]. It is important to understand that dopamine is connected to our perception of time [6022.24:6028], and when we focus on a reward that comes at the end, we undermine our ability to access dopamine from effort [6167.84:6174.16]. We can learn to cultivate growth mindset by accessing rewards from effort and doing [6142.08:6149.28], and this can be seen in people like David Goggins and in activities like intermittent fasting [6491.84:6496]. Intermittent fasting allows us to attach dopamine release to effort and strain [6480.32:6485.28], and when we fast, our dopamine levels increase when we eat due to the deprivation state [6572.88:6579.76][6601.84:6607.04].
    The dopamine pathway is vulnerable to subjective interpretation and can reinforce behaviors that are good for us [6660.16:6697.200000000001]. Wellbutrin (bupryron) is a prescription drug that increases dopamine and nor epinephrine to treat depression [6701.84:6706.4800000000005], and macunipurines is an over-the-counter supplement that increases dopamine and is used to treat symptoms of Parkinson's disease and reduce prolactin levels [7189.2:7194.56]. However, dopamine-increasing substances, such as macunipurines and L-tyrosine, can cause a crash or reduction in baseline dopamine after the effects wear off [7284.08:7289.84].
    L-tyrosine and mercur-mecun appearance are two common over-the-counter supplements that people take to increase their dopamine levels [7317.28:7322.88]. It takes about 30-45 minutes to peak after ingestion [7342.88:7348.160000000001]. Taking melatonin can reduce dopamine levels [7526.719999999999:7533.599999999999], and viewing bright lights between 10 p.m. and 4 a.m. also has this effect [7594.96:7600.4]. Chocolate and PEA (beta phenylethyl amine) are two foods that naturally increase dopamine [7640.24:7648.32]. Hooperzine A is a compound gaining popularity for its ability to stimulate dopamine release [7757.4400000000005:7762.96]. Oxytocin and social interactions also trigger dopamine release [7869.44:7876.32], so engaging in quality social interactions is important for maintaining healthy dopamine levels [7939.04:7945.679999999999].
    The text discusses the dopamine pathways and how they can be controlled through various compounds, behaviors, and tools [7945.679999999999:8186.320000000001]. It briefly mentions maca and the gut microbiome as indirect influences on dopamine [7951.36:7977.76], and how cold water exposure can lead to huge and sustained increases in dopamine [7998.72:8006]. It encourages listeners to take control of their dopamine system [8022.72:8028.64] and to understand the mechanisms and tools that can be used to modulate and adjust their dopamine levels [8044:8048.72]. Finally, it suggests subscribing to the YouTube channel, leaving a comment or suggestion for a guest or topic, subscribing on Apple or Spotify, and supporting the Huberman Lab [8067.84:8122.88].`,
  },
  {
    videoId: 'he_BL6Q5u1Y',
    url: 'https://www.youtube.com/watch?v=he_BL6Q5u1Y',
    title: "Why don't rocket engines melt? How engineers keep engines cool",
    channelName: 'Everyday Astronaut',
    channelPhotoURL:
      'https://i.iheart.com/v3/surl/aHR0cDovL2ltYWdlLmloZWFydC5jb20vaW1hZ2VzL3JvdmkvMTA4MC8wMDA0LzYyMS9NSTAwMDQ2MjEyOTguanBn?ops=fit%28480%2C480%29%2Crun%28%22circle%22%29&sn=eGtleWJhc2UyMDIxMTExMDr6wTfxSLKR9QVYeEulIZtsIS7J2qMY_Jr_NOgn5UIk8g%3D%3D&surrogate=1cOXl179JY-syhxYSCX6Q1a_Mcu6UO8d-F4oJzpZf1hcUbJr4aImx9kIE07rzAxa-c__9VBykLHb2tboGwaKl7GXobEoz-wg7t-wBDVv2olf00wGyy0hq970-r3liGYqfwNEOsVNJ4dBHoMUtb31Q2HULr_PMu4XaeiZmT_IAdAe9uP1zIDs4K483OrTPyqL6b49S1p5WBL8RmeLdTj6XOgNqXZth6wFnVk%3D',
    numViews: '2.3M',
    summary: `The Everyday Astronaut introduces SpaceX's Raptor engine, which runs at 3500 Kelvin, half as hot as the surface of the sun [11.040000000000001:13.8]. There are a few methods used to keep the engine from melting, such as using thick metal walls as a heat sink [156.74:159.66], running the engine with more fuel or oxidizer [270.62:275.42], and using a material with high melting point to absorb heat [469.38:474.9].
    Rocket engines are cooled using several methods, such as ablative cooling, where a layer of material melts away to take heat with it [484.21999999999997:485.21999999999997]. Regenerative cooling is also used, where fuel and/or oxidizer flows through the walls of the combustion chamber and nozzle [645.82:651.0600000000001], creating a boundary of cooler fluid between the hot combustion gases and the walls [878.7800000000001:882.6600000000001]. Other methods, such as film cooling, involve injecting a fluid between the combustion chamber and nozzle surface and the hot combustion gases, often by drilling holes in the walls [967.98:972.72], or by having a higher concentration of fuel/oxidizer injectors on the outer perimeter of the injector face [912.74:918.1800000000001]. In some cases, unburnt carbon may also form a layer of soot on the chamber walls [984.4200000000001:989.66].
    This text talks about various ways to keep rocket engines from melting, such as heat sink tactics, regenerative cooling, and film cooling. Tom Marcusic explains that the soot that sticks to the walls of the reaver engine acts as a beneficial thermal barrier coating. The gas generator exhaust can be used to film cool some of the nozzle [1094.42:1115.5]. Niobium alloy is often used in nozzle extensions and radiates heat away due to the lack of an atmosphere in space [1377.94:1424.5800000000002]. All these tricks ensure the rocket engines don't melt [1495.02:1525.82].
    Tim Dodd, the everyday astronaut, advertises his exclusive Patreon channel [1530.86:1535.1] as well as his web store [1544.26:1549.66], which includes items such as the RD-171 shirt [1549.66:1554.58], the space shuttle ejection hoodie [1558.62:1563.42], and the RS-25 shirt [1563.42:1569.26] [1569.26:1570.26]. He ends his advertisement by introducing himself [1571.66:1575.6200000000001] and wishing his viewers a good day.`,
  },
];

export default demoVideos;
