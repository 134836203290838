import DataSource from 'data/DataSource';
import useDataSource from 'data/useDataSource';
import Message from 'types/Message';

// TODO: reset when userID or videoID changes
class ChatMessagesDS extends DataSource {
  static __instance: any;
  static get() {
    if (!this.__instance) {
      this.__instance = new ChatMessagesDS();
    }
    return this.__instance;
  }

  constructor() {
    super({ messages: [] });
  }

  getMessages(): Message[] {
    return this.getState()?.messages || [];
  }

  clear(): void {
    this.set({ messages: [] });
  }

  addMessage(messages: Message) {
    this.patch({
      messages: [...this.getMessages(), messages],
    });
  }

  addMessages(messages: Message[]) {
    this.patch({
      messages: [...this.getMessages(), ...messages],
    });
  }
}

export const useChatMessages = (params?: { dependencies?: any[]; transform?: Function }) => {
  return useDataSource(ChatMessagesDS.get(), params?.dependencies || [], params?.transform);
};

export default ChatMessagesDS;
