import React, { useState } from 'react';
import ChatFeed from 'components/ChatFeed';
import { ChatIcon, ChatFillIcon, TranscriptIcon, TranscriptFillIcon, PlusIcon } from 'Icons';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import Player from 'types/Player';
import Transcript from 'components/Transcript';
import VideoPlayer from 'components/VideoPlayer';
import maxHeightOnMobileSafariCSS from 'utils/maxHeightOnMobileSafariCSS';

type TabTypes = 'transcript' | 'chat' | 'newVideo';

const MobileVideoPage = ({
  playerRef,
  isPlaying,
  setIsPlaying,
}: {
  playerRef: Player;
  isPlaying: any;
  setIsPlaying: Function;
}) => {
  const [currentTab, setCurrentTab]: [TabTypes, Function] = useState<TabTypes>('chat');

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100vw',
        minHeight: '100vh',
        position: 'relative',
        ...maxHeightOnMobileSafariCSS,
      }}
    >
      <div
        css={{
          overflow: 'hidden',
          minHeight: 260,
          height: 260,
          width: '100%',
        }}
      >
        <VideoPlayer playerRef={playerRef} isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
      </div>
      <__Tab setCurrentTab={setCurrentTab} currentTab={currentTab} />

      {currentTab === 'transcript' ? (
        <Transcript playerRef={playerRef} setIsPlaying={setIsPlaying} />
      ) : null}
      <ChatFeed
        playerRef={playerRef}
        setIsPlaying={setIsPlaying}
        shouldHideChat={currentTab !== 'chat'}
      />
    </div>
  );
};

const __Tab = ({
  setCurrentTab,
  currentTab,
}: {
  setCurrentTab: Function;
  currentTab: TabTypes;
}) => {
  const onClickNewVideo = useUpdatingCallback(() => window.open(window.location.origin, '_blank'));
  return (
    <div
      css={{
        position: 'relative',
        width: '100%',
      }}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          position: 'absolute',
          paddingLeft: 6,
          paddingRight: 6,
          borderRadius: 12,
          minHeight: 42,
          width: 'fit-content',
          background: 'rgba(128,128,128,0.9)',
          top: 20,
          left: '50%',
          transform: 'translate(-50%)',
        }}
      >
        <__TabButton
          setCurrentTab={setCurrentTab}
          Icon={currentTab === 'chat' ? ChatFillIcon : ChatIcon}
          value='chat'
        />
        <__TabButton
          setCurrentTab={setCurrentTab}
          Icon={currentTab === 'transcript' ? TranscriptFillIcon : TranscriptIcon}
          value='transcript'
        />
        <__TabButton onClick={onClickNewVideo} Icon={PlusIcon} value='newVideo' />
      </div>
    </div>
  );
};

const __TabButton = ({
  setCurrentTab,
  onClick,
  Icon,
  value,
}: {
  setCurrentTab?: Function;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  Icon: any;
  value: TabTypes;
}) => {
  const onTabClick = useUpdatingCallback((evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setCurrentTab && setCurrentTab(value);
    onClick && onClick(evt);
  });

  return (
    <div
      css={{
        opacity: 1,
        backgroundColor: 'transparent',
        padding: 6,
        color: 'white',
        display: 'flex',
        alignItems: 'center',
      }}
      onClick={onTabClick}
    >
      <Icon />
    </div>
  );
};

export default MobileVideoPage;
