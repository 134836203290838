import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/react';
import Feedback from 'types/Feedback';
import ChatMessagesDS from 'data/ChatMessagesDS';
import AuthDS, { SESSION_EXPIRED_MESSAGE } from 'data/AuthDS';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

class CallAPI {
  static async addEmail({ email }) {
    return await __genCallAPI({ apiName: 'addEmail', params: { email } });
  }

  static async postVideo({ url }) {
    // timeout after 99 seconds (DigitalOcean times out after 100 seconds)
    return await __genCallAPI({
      apiName: 'postVideo',
      params: { url },
      config: { timeout: 99_000 },
    });
  }

  static async getTranscript(params: { videoId: string }) {
    return await __genCallAPI({ apiName: 'getTranscript', params });
  }

  static async postFeedback(params: { feedback: Feedback }) {
    return await __genCallAPI({ apiName: 'postFeedback', params });
  }

  /* 
    Chat APIs
  */

  static async getQuiz(params: { videoId: string; quizHistory: any[] }) {
    return await __genHandleChatAPIError({ apiName: 'generateQuiz', params });
  }

  static async getAnswer(params: { videoId: string; question: string }) {
    return await __genHandleChatAPIError({ apiName: 'getAnswer', params });
  }

  static async getSummary(params: { videoId: string }) {
    return await __genHandleChatAPIError({ apiName: 'getSummary', params });
  }

  /*
    Auth API
  */
  static async getCurrentSession() {
    return await __genCallAPI({ apiRoute: 'auth', apiName: 'getCurrentSession' });
  }

  static async logout(params: { accessToken: string }) {
    return await __genCallAPI({ apiRoute: 'auth', apiName: 'logout', params });
  }
}

async function __genHandleChatAPIError({ apiName, params }: { apiName: string; params: any }) {
  try {
    return await __genCallAPI({ apiName, params });
  } catch (error) {
    // authorization error or session expired
    if (error.response.status === 403) {
      AuthDS.get().setUserId('');
    } else {
      ChatMessagesDS.get().addMessage({
        user: 'CHATGPT',
        id: uuidv4(),
        text: `An error occurred, our team has been notified and will investigate. Please try again. If the issue persists, refresh your tab. `,
      });
      Sentry.withScope((scope) => {
        const data = {
          apiName,
          params,
        };
        scope.setExtra('data', data);
        Sentry.captureException(error);
      });
    }
  }
}

async function __genCallAPI({
  apiRoute = 'api',
  apiName,
  params,
  config = {},
}: {
  apiRoute?: string;
  apiName: string;
  params?: any;
  config?: any;
}) {
  let authParams = {};
  const accessToken = window.localStorage.getItem('accessToken');
  if (accessToken) {
    authParams = { accessToken };
  } else {
    const anonVisitorId = window.localStorage.getItem('anonVisitorId');
    authParams = { anonVisitorId };
  }

  return await instance.post(`/${apiRoute}/${apiName}`, { ...params, ...authParams }, config);
}

export default CallAPI;
