import React from 'react';
import colors from '../colors';
import Dimensions from 'utils/Dimensions';
import { isMobile } from 'react-device-detect';
import Demo from 'components/Demo';
import YouTubeURLInputField from 'components/YouTubeURLInputField';
import maxHeightOnMobileSafariCSS from 'utils/maxHeightOnMobileSafariCSS';

const LandingPage = () => {
  return (
    <div
      css={{
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: isMobile ? '100vw' : Dimensions.MAX_BODY_WIDTH,
        minWidth: isMobile ? '100vw' : Dimensions.MIN_BODY_WIDTH,
        flex: 1,
        ...maxHeightOnMobileSafariCSS,
      }}
    >
      <div
        css={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100vw',
          ...maxHeightOnMobileSafariCSS,
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            padding: 5,
            color: colors.const_white,
          }}
        >
          <h1
            css={{
              margin: 'auto',
              textAlign: 'center',
              paddingBottom: '5px',
              fontSize: isMobile ? '28px' : '40px',
            }}
          >
            Learn more from videos {isMobile ? <br /> : ''} with AI
          </h1>
          <p css={{ marginTop: 'auto', textAlign: 'center', fontSize: isMobile ? '16px' : '24px' }}>
            Ask questions. Get a summary. Quiz yourself.
          </p>
          {isMobile ? <YouTubeURLInputField /> : null}
        </div>
        <Demo />
      </div>
      <__Footer />
    </div>
  );
};

const __Footer = () => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        position: 'fixed',
        bottom: 10,
        '& a': {
          textDecoration: 'none',
          color: 'black',
        },
        color: 'black',
        alignItems: 'flex-start',
        opacity: 0.5,
      }}
    >
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://twitter.com/rami_rustom/status/1608957781729738752?s=20&t=2s6sF4tEDGvAUh_jLBQc3Q'
      >
        How does it work?
      </a>
      <div css={{ marginRight: 12, marginLeft: 12 }}>&middot;</div>
      <a href='mailto:kiwi.video.ai@gmail.com'>Get in touch</a>
      <div css={{ marginRight: 12, marginLeft: 12 }}>&middot;</div>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://bristle-approval-ebb.notion.site/kiwi-Privacy-Policy-7f8af807c7a5419eada9c65da854170e'
      >
        Privacy Policy
      </a>
    </div>
  );
};

export default LandingPage;
